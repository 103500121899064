/*-----------------------
    Common CSS
-------------------------*/

* {
    margin: 0px;
    padding: 0px;
}

html {
    padding: 0px;
    margin: 0 auto;
}

body {
    margin: 0px auto;
    padding: 0px;
    background-color: var(--bg) !important;
}

a:hover {
    text-decoration: none;
}

pre,
p {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

ul {
    padding: 0;
    margin: 0px;
}

textarea {
    resize: none !important;
}

button,
select {
    cursor: pointer;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

ul {
    padding: 0px;
    margin: 0px;
}

img {
    width: 100%;
    display: block;
}

a:hover {
    text-decoration: none;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    text-decoration: none;
}


/*-----------------------
    Common CSS Ends
-------------------------*/


/*-----------------------
    Navbar
-------------------------*/

._grover_grocery_navbar {
    background: none !important;
}

._grover_grocery_list_item {
    margin: 0 16px;
}

._grover_grocery_logo {
    width: 80px;
    height: auto;
    margin-right: 100px;
}

._toggler {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

._grover_grocery_list_link {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #4D4D4D !important;
    padding: 8px 0 !important;
}

._active {
    color: #F55253 !important;
}

._grover_grocery_list_link:hover {
    color: #F55253 !important;
}

._search_btn_list {
    list-style: none;
    visibility: hidden;
}

._nav_search_form {
    position: relative;
}

._search_ic {
    position: absolute;
    top: 12px;
    left: 20px;
}

._nav_search_input {
    width: 288px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 12px 66px 12px 44px;
    border: none;
}

._nav_search_input:focus::-webkit-input-placeholder {
    opacity: 0;
}

._nav_search_input:focus::-moz-placeholder {
    opacity: 0;
}

._nav_search_input:focus:-ms-input-placeholder {
    opacity: 0;
}

._nav_search_input:focus::-ms-input-placeholder {
    opacity: 0;
}

._nav_search_input:focus::placeholder {
    opacity: 0;
}

._nav_search_input::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color2);
    -webkit-transition: .2s all ease;
    transition: .2s all ease;
}

._nav_search_input::-moz-placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color2);
    -moz-transition: .2s all ease;
    transition: .2s all ease;
}

._nav_search_input:-ms-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color2);
    -ms-transition: .2s all ease;
    transition: .2s all ease;
}

._nav_search_input::-ms-input-placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color2);
    -ms-transition: .2s all ease;
    transition: .2s all ease;
}

._nav_search_input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color2);
    -webkit-transition: .2s all ease;
    -o-transition: .2s all ease;
    transition: .2s all ease;
}

._cart_ic {
    position: absolute;
    top: 10px;
    right: 20px;
}

._cart_ic::before {
    position: absolute;
    content: '';
    background: #F2F2F2;
    height: 27px;
    width: 2px;
    left: -16px;
    top: 2px;
}

._cart_ic::after {
    content: "2";
    position: absolute;
    background: #F54B4B;
    -webkit-box-shadow: -6px 9px 24px -6px rgba(238, 67, 67, 0.68);
    box-shadow: -6px 9px 24px -6px rgba(238, 67, 67, 0.68);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.005em;
    color: #FFFDFD;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: -24px;
    right: -24px;
}


/*-----------------------
    Navbar Ends
-------------------------*/


/*-----------------------
    Header
-------------------------*/

._header_grover_grocery_wrapper {
    padding: 40px 0;
}

._header_grover_grocery_wrap {
    padding: 80px 0;
}

._header_grover_grocery_content_top_txt {
    background: #FDECEC;
    border-radius: 23px;
    padding: 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 20px 0;
}

._header_grover_grocery_content_top_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #F55556;
    margin-left: 4px;
}

._header_top_txt_img {
    margin: 0 2px;
}

._strawberry_img {
    width: 24x;
    height: 24px;
}

._header_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 74px;
    line-height: 88px;
    letter-spacing: -0.01em;
    color: var(--color2);
}

._header_red_clr {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 74px;
    line-height: 88px;
    letter-spacing: -0.05em;
    color: #F54748;
}

._header_grover_grocery_content_para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.005em;
    color: var(--color3);
    max-width: 80%;
    margin: 22px 0;
}

._header_grover_grocery_btn_wrap {
    margin: 50px 0 10px 0;
}

._header_now_btn {
    background: var(--bg3);
    border-radius: 12px;
    width: 186px;
    padding: 25px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFAFA !important;
    display: block;
    border: 1px solid transparent;
}

._header_now_btn:hover {
    border: 1px solid var(--bg3);
    color: var(--bg3) !important;
    background: #FFFAFA !important;
}

._header_process_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 224px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    letter-spacing: -0.01em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--color2);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: none;
    border: none;
    transition: .2s all ease;
}

._header_process_btn:hover {
    color: var(--color3);
}

._play_ic {
    border-radius: 50%;
    -webkit-box-shadow: 0px 20px 49px -10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 20px 49px -10px rgba(0, 0, 0, 0.08);
}


/*-----------------------
    Header Ends
-------------------------*/


/*--------------------------
    Service Grover Grocery
---------------------------*/

._service_grover_grocery_wrapper {
    padding: 100px 0;
    background-color: var(--bg2);
}

._service_grover_grocery_heading {
    text-align: center;
}

._service_grover_grocery_heading_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #F54748;
    margin: 0 0 8px 0;
}

._service_grover_grocery_heading_title2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-transform: capitalize;
    color: var(--color2);
    max-width: 50%;
    margin: 0 auto;
}

._service_grover_grocery_content_wrap {
    margin: 60px 0 20px 0;
}

._service_grover_grocery_content_inner {
    position: relative;
}

._service_grover_grocery_content_bg {
    position: absolute;
    width: 310px;
    height: 350px;
    background: #FE6A6A;
    border-radius: 12px;
    left: 28px;
    z-index: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0;
    -webkit-transition: .2s all ease;
    -o-transition: .2s all ease;
    transition: .2s all ease;
}

._service_grover_grocery_content_inner:hover ._service_grover_grocery_content_bg {
    opacity: 1;
    left: 38px;
    top: -3px;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

._service_grover_grocery_content {
    margin: 20px;
    background-color: var(--bg);
    border-radius: 12px;
    padding: 50px 28px;
    position: relative;
    z-index: 2;
}

._service_grover_grocery_content_img {
    width: auto;
    height: 60px;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0px 25px 30px rgba(248, 67, 68, 0.1));
    filter: drop-shadow(0px 25px 30px rgba(248, 67, 68, 0.1));
}

._service_grover_grocery_content_txt {
    text-align: center;
    margin: 60px 0 10px 0;
}

._service_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.01em;
    color: var(--color2);
    margin: 0 0 15px 0;
}

._service_grover_grocery_content_para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: var(--color3);
    max-width: 90%;
    margin: 0 auto;
}


/*-----------------------------
    Service Grover Grocery Ends
------------------------------*/


/*-------------------------
    Feature Grover Section
-------------------------*/

._feature_grover_grocery_wrapper {
    padding: 50px 0;
}

._feature_grover_grocery_content {
    margin: 180px 0 20px 0;
}

._feature_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #03A66B;
    text-transform: uppercase;
    margin: 0 0 8px 0;
}

._feature_grover_grocery_content_title2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-transform: capitalize;
    color: var(--color2);
    max-width: 75%;
    margin: 0 0 22px 0;
}

._feature_grover_grocery_content_para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #676767;
    max-width: 70%;
}

._feature_grover_grocery_content_btn {
    margin: 50px 0 20px 0;
}

._feature_content_btn {
    display: block;
    background: var(--bg3);
    border-radius: 12px;
    width: 200px;
    padding: 24px 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    border: 1px solid transparent;
    color: #FFFAFA !important;
}

._feature_content_btn:hover {
    background: var(--bg);
    color: var(--bg3) !important;
    border: 1px solid var(--bg3);
}


/*------------------------------
    Feature Grover Section Ends
------------------------------*/


/*-------------------------
    Feature 2 Grover Section
-------------------------*/

._feature_2_grover_grocery_wrapper {
    padding: 50px 0;
}

._feature_2_grover_grocery_content {
    margin: 200px 0 20px 0;
}

._feature_2_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #F9BA45;
    margin: 0 0 8px 0;
}

._feature_2_grover_grocery_content_title2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-transform: capitalize;
    color: var(--color2);
    max-width: 90%;
    margin: 0 0 22px 0;
}

._feature_2_grover_grocery_content_para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #676767;
    max-width: 80%;
}

._feature_2_grover_grocery_content_btn {
    margin: 50px 0 20px 0;
}

._feature_2_content_btn {
    display: block;
    background: var(--bg3);
    border-radius: 12px;
    width: 200px;
    padding: 24px 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    border: 1px solid transparent;
    color: #FFFAFA !important;
}

._feature_2_content_btn:hover {
    background: var(--bg);
    color: var(--bg3) !important;
    border: 1px solid var(--bg3);
}


/*------------------------------
    Feature 2 Grover Section Ends
------------------------------*/


/*-----------------------------
    CTA Grover Grocery Section
-----------------------------*/

._cta_grover_grocery_wrapper {
    padding: 100px 0;
    background-color: var(--bg2);
}

._cta_grover_grocery_img {
    width: 320px;
    margin: 0 auto;
}

._cta_grover_grocery_content {
    margin: 80px 0 20px 0;
}

._cta_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #F54748;
    margin: 0 0 8px 0;
}

._cta_grover_grocery_content_title2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-transform: capitalize;
    color: #2E2E2E;
    max-width: 80%;
    margin: 0 0 22px 0;
}

._cta_grover_grocery_content_para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    max-width: 80%;
    color: var(--color3);
    margin: 0 0 20px 0;
}

._cta_grover_grocery_content_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 60px 0 20px 0;
}

._app_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 200px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: var(--color2) !important;
    padding: 18px 2px;
    border: 1px solid var(--border-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 12px;
}

._playstore_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 200px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: var(--color2) !important;
    padding: 18px 2px;
    border: 1px solid var(--border-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 12px;
    visibility: hidden;
}


/*----------------------------------
    CTA Grover Grocery Section Ends
----------------------------------*/


/*--------------------------------
    Footer Grover Grocery Section
--------------------------------*/

._footer_grover_grocery_wrapper {
    padding: 50px 0 0 0;
}

._footer_grover_grocery_top {
    padding: 30px 0;
}

._footer_logo_img {
    width: auto;
}

._footer_grover_grocery_logo_wrap {
    margin: 20px 0;
}

._footer_grover_grocery_logo {
    max-width: 120px;
    margin: 20px 0;
}

._footer_grover_grocery_content_logo_txt {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--color3);
    max-width: 75%;
    margin: 25px 0 10px 0;
}

._footer_grover_grocery_content {
    margin: 20px 0;
}

._footer_grover_grocery_content_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--color2);
}

._footer_grover_grocery_content_list {
    margin: 30px 0 10px 0;
}

._footer_grover_grocery_list {
    list-style: none;
}

._footer_grover_grocery_list_link {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: var(--color3) !important;
}

._footer_grover_grocery_list_link:hover {
    color: var(--color) !important;
}

._footer_grover_grocery_content {
    margin: 30px 0 10px 0;
}

._footer_grover_grocery_content_txt {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #888888;
}

._footer_grover_grocery_bottom {
    padding: 0 0 30px 0;
}

._footer_divider {
    background-color: #ECECEC;
    margin: 30px 0;
}

._footer_grover_grocery_copyright_txt {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8C8C8C;
}

._footer_grover_grocery_condition {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

._footer_grover_grocery_condition_link {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #747474 !important;
    margin: 0 10px;
    display: block;
}

._footer_grover_grocery_condition_link:hover {
    color: var(--color) !important;
}


/*-------------------------------------
    Footer Grover Grocery Section Ends
--------------------------------------*/


/*--------------
    Responsive
---------------*/


/* Desktop/Laptop */

@media only screen and (max-width: 1500px) {}

@media (max-width: 1399px) {
    ._header_grover_grocery_content_title {
        font-size: 48px;
        line-height: 58px;
    }
    ._header_red_clr {
        font-size: 48px;
        line-height: 58px;
    }
    ._service_grover_grocery_heading_title2 {
        font-size: 32px;
        line-height: 44px;
        max-width: 100%;
    }
    ._service_grover_grocery_heading_title {
        font-size: 16px;
    }
    ._service_grover_grocery_content_bg {
        width: 252px;
        height: 100%;
    }
    ._service_grover_grocery_content {
        margin: 10px;
        padding: 40px 26px;
    }
    ._service_grover_grocery_content_para {
        max-width: 100%;
    }
    ._feature_grover_grocery_content_title {
        font-size: 16px;
    }
    ._feature_grover_grocery_content_title2 {
        font-size: 32px;
        line-height: 44px;
        max-width: 90%;
    }
    ._feature_grover_grocery_content_para {
        font-size: 16px;
        max-width: 100%;
    }
    ._feature_content_btn {
        width: 180px;
        padding: 18px 20px;
        font-size: 16px;
    }
    ._feature_2_grover_grocery_content_title {
        font-size: 16px;
    }
    ._feature_2_grover_grocery_content_title2 {
        font-size: 32px;
        line-height: 44px;
        max-width: 90%;
    }
    ._feature_2_grover_grocery_content_para {
        font-size: 16px;
        max-width: 100%;
    }
    ._feature_2_content_btn {
        width: 180px;
        padding: 18px 20px;
        font-size: 16px;
    }
    ._cta_grover_grocery_content_title {
        font-size: 16px;
    }
    ._cta_grover_grocery_content_title2 {
        font-size: 32px;
        line-height: 44px;
        max-width: 90%;
    }
    ._cta_grover_grocery_content_para {
        font-size: 16px;
        max-width: 90%;
    }
    ._cta_grover_grocery_content_btn {
        margin: 40px 0 20px 0;
    }
    ._app_btn {
        font-size: 16px;
        padding: 12px 2px;
    }
    ._playstore_btn {
        font-size: 16px;
        padding: 12px 2px;
    }
}


/* Desktop/Laptop */


/* Ipad pro */

@media only screen and (max-width: 1199px) {
    ._header_grover_grocery_content_title {
        font-size: 38px;
    }
    ._header_red_clr {
        font-size: 38px;
    }
    ._header_grover_grocery_content_para {
        font-size: 16px;
        max-width: 100%;
    }
    ._header_now_btn {
        width: 160px;
        font-size: 16px;
    }
    ._header_process_btn {
        width: 200px;
        font-size: 16px;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }
    ._service_grover_grocery_content_title {
        font-size: 18px;
    }
    ._service_grover_grocery_content_bg {
        height: 105%;
    }
    ._feature_grover_grocery_content {
        margin: 100px 0 20px 0;
    }
    ._feature_2_grover_grocery_content {
        margin: 100px 0 20px 0;
    }
    ._footer_grover_grocery_content_logo_txt {
        font-size: 14px;
        max-width: 95%;
    }
    ._footer_grover_grocery_list_link {
        font-size: 14px;
    }
    ._footer_grover_grocery_content_txt {
        font-size: 14px;
    }
}


/* Ipad pro */

@media only screen and (max-width: 991px) {
    ._grover_grocery_list_item {
        margin: 12px 16px;
        text-align: center;
    }
    ._nav_search_form {
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
    }
    ._service_grover_grocery_content {
        margin: 0;
        padding: 30px 22px;
    }
    ._service_grover_grocery_content_bg {
        width: 190px;
    }
    ._feature_2_grover_grocery_wrapper {
        padding: 0 0 100px 0;
    }
    ._footer_grover_grocery_top {
        text-align: center;
    }
    ._footer_logo_img {
        margin: 0 auto;
    }
    ._footer_grover_grocery_content_logo_txt {
        max-width: 100%;
    }
}


/* Ipad pro */


/* Ipad/Mobile L */

@media only screen and (max-width: 767px) {
    ._header_grover_grocery_content {
        text-align: center;
    }
    ._header_grover_grocery_content_top_txt {
        margin: 0 auto 20px auto;
    }
    ._service_grover_grocery_wrapper {
        padding: 50px 0;
    }
    ._header_grover_grocery_wrap {
        padding: 60px 0 10px 0;
    }
    ._service_grover_grocery_content {
        margin: 10px 0;
    }
    ._service_grover_grocery_content_bg {
        width: 96%;
    }
    ._feature_grover_grocery_content {
        margin: 80px 0 20px 0;
        text-align: center;
    }
    ._feature_grover_grocery_content_title2 {
        max-width: 100%;
    }
    ._feature_content_btn {
        margin: 0 auto;
    }
    ._feature_2_grover_grocery_content {
        margin: 80px 0 20px 0;
        text-align: center;
    }
    ._feature_2_grover_grocery_content_title2 {
        max-width: 100%;
    }
    ._feature_2_content_btn {
        margin: 0 auto;
    }
    ._cta_grover_grocery_content {
        text-align: center;
    }
    ._cta_grover_grocery_content_title2 {
        max-width: 100%;
    }
    ._cta_grover_grocery_content_para {
        max-width: 100%;
    }
    ._cta_grover_grocery_wrapper {
        padding: 50px 0;
    }
    ._footer_grover_grocery_wrapper {
        padding: 20px 0 0 0;
    }
    ._footer_grover_grocery_bottom {
        text-align: center;
    }
    ._footer_grover_grocery_condition {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    ._footer_divider {
        margin: 10px 0 30px 0;
    }
}


/* Ipad/Mobile L */


/* Mobile */

@media only screen and (max-width: 575px) {
    ._header_now_btn {
        width: 100%;
        font-size: 16px;
        padding: 20px 12px;
    }
    ._header_process_btn {
        width: 100%;
        font-size: 12px;
    }
    ._play_img {
        width: 60px;
    }
    ._cta_grover_grocery_content_btn {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }
    ._footer_grover_grocery_logo {
        max-width: 120px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 420px) {
    ._header_grover_grocery_content_top_title {
        font-size: 14px;
    }
    ._header_grover_grocery_content_title {
        font-size: 30px;
    }
    ._header_red_clr {
        font-size: 30px;
    }
    ._header_grover_grocery_content_para {
        font-size: 14px;
    }
    ._header_now_btn {
        padding: 14px 10px;
    }
    ._play_img {
        width: 50px;
    }
    ._service_grover_grocery_heading_title {
        font-size: 14px;
    }
    ._service_grover_grocery_heading_title2 {
        font-size: 24px;
    }
    ._feature_grover_grocery_content_title {
        font-size: 14px;
    }
    ._feature_grover_grocery_content_title2 {
        font-size: 24px;
    }
    ._feature_grover_grocery_content_para {
        font-size: 14px;
    }
    ._feature_2_grover_grocery_content_title {
        font-size: 14px;
    }
    ._feature_2_grover_grocery_content_title2 {
        font-size: 24px;
    }
    ._feature_2_grover_grocery_content_para {
        font-size: 14px;
    }
    ._cta_grover_grocery_content_title {
        font-size: 14px;
    }
    ._cta_grover_grocery_content_title2 {
        font-size: 24px;
    }
    ._cta_grover_grocery_content_para {
        font-size: 14px;
        max-width: 100%;
    }
    ._app_btn {
        font-size: 14px;
        padding: 12px 2px;
        width: 160px;
    }
    ._playstore_btn {
        font-size: 14px;
        padding: 12px 2px;
        width: 160px;
    }
}

@media only screen and (max-width: 375px) {
    ._header_grover_grocery_btn {
        height: 100%;
    }
    ._header_process_btn {
        height: 100%;
    }
    ._play_img {
        width: 40px;
    }
}


/* Mobile */


/*------------------
    Responsive Ends
------------------*/


/*------------------
    Variable
------------------*/

:root {
    /* Colors */
    --color: #000;
    --color2: #2E2E2E;
    --color3: #676767;
    --color4: #4D4D4D;
    /* Colors */
    /* Background */
    --bg: #fff;
    --bg2: #FEF5F5;
    --bg3: #D71920;
    /* Background */
    /* Border */
    --border-color: #EEDCDC !important;
    --border-color2: #DDEDF4 !important;
    /* --border-color3: #31C3E0 !important; */
    /* Border */
}


/*------------------
    Variable Ends
------------------*/