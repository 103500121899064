.lightbox-gallery {
	background-image: linear-gradient(#FFFFFF, #FFFFFF);
	background-repeat: no-repeat;
	color: #000;
	overflow-x: hidden;
    margin: 120px 0 80px 0;
}

.lightbox-gallery p {
	color: #676767
}

.lightbox-gallery h2 {
	font-weight: bold;
    margin: 0 auto;
	margin-bottom: 40px;
	padding-top: 40px;
	color: #2E2E2E
}

@media (max-width:767px) {
	.lightbox-gallery h2 {
		margin-bottom: 25px;
		padding-top: 25px;
		font-size: 24px
	}
}

.lightbox-gallery .intro {
	font-size: 16px;
	max-width: 800px;
	margin: 0 auto 40px;
}

.lightbox-gallery .intro p {
    margin: 0 auto;
	margin-bottom: 0;
    max-width: max-content;
}

.lightbox-gallery .photos {
	padding-bottom: 20px
}

.lightbox-gallery .item {
	padding-bottom: 30px
}